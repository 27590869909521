import { HelperTooltip } from '@/shared';
import { Card, Flex, Box, Text, CSS } from '@grupoboticario/flora-react';
import { TextInfoProps } from '@/types';
import { IrDataCardPercentInfo } from '@/components/control';

interface IrCardInfoProps {
  css?: CSS;
  helpText: string;
  icon: React.ReactNode;
  textInfo: string | TextInfoProps[];
  title: string;
}

export const IrCardInfo = ({ title, helpText, textInfo, icon, css }: IrCardInfoProps) => {
  return (
    <Card css={{ padding: '$8 $6', minHeight: '128px', ...css }}>
      <Flex>
        <Flex
          justify='center'
          align='center'
          css={{
            width: '68px',
            height: '68px',
            borderRadius: '$medium',
            backgroundColor: '$colors$backgroundTertiary',
            '& svg': {
              scale: '1.8',
              color: '$colors$nonInteractiveEmphasis',
            },
          }}
        >
          {icon}
        </Flex>
        <Box css={{ paddingLeft: '$4' }}>
          <Flex>
            <Text as='p' size='bodyLargeStandardMedium' css={{ paddingRight: '$1' }}>
              {title}
            </Text>
            <HelperTooltip text={helpText} isQuestionMark />
          </Flex>

          {!Array.isArray(textInfo) ? (
            <Text as='p' size='titleMobileBold' data-testid='text-info' weight='heavy'>
              {textInfo}
            </Text>
          ) : (
            <IrDataCardPercentInfo textInfo={textInfo} />
          )}
        </Box>
      </Flex>
    </Card>
  );
};
