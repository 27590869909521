import { MailingStoreAssistantStateProps, MailingStoreControlStateProps } from '@/types';
import { create } from 'zustand';
import { controlMailingSlice } from './control';
import { assistantMailingSlice } from './assistant';
import { devtools } from 'zustand/middleware';

export const useAssistantMailingStore = create<MailingStoreAssistantStateProps>()(
  devtools((...state) => ({
    ...assistantMailingSlice(...state),
  }))
);

export const useControlMailingStore = create<MailingStoreControlStateProps>()(
  devtools((...state) => ({
    ...controlMailingSlice(...state),
  }))
);
