import { postDistributeBase, useGetAssistantTeamMembers } from '@/api';
import { ControlFiler, Columns as columns } from '@/components/control';
import { ContentWrapper, DataTable, LoadStatus, TagInfo } from '@/shared';
import { numberFormatter } from '@/shared/utils';
import { useControlMailingStore } from '@/store';
import { AssistantTeamMembers, AssistantTeamMembersTable, AssistantTeamStore } from '@/types';
import { displayToaster, useSlowResponseAlert } from '@/utils';
import { Box, FloraButton, Flex } from '@grupoboticario/flora-react';
import { getCoreRowModel, getSortedRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

const formatData = (dataTable: AssistantTeamMembers[], responsibleTeam: AssistantTeamStore[]) =>
  dataTable?.map((data) => {
    let responsibleName = '--';
    responsibleTeam.map((team) => {
      if (data.groupId === team._id) {
        responsibleName = team.responsible;
      }
    });

    return { ...data, responsibleName: responsibleName ? responsibleName : '--' };
  });

export const AssistantTeamMembersList = () => {
  const navigate = useNavigate();
  const setShowSlowResponseAlert = useSlowResponseAlert({
    message: 'A distruição da base está sendo processada. Por favor, aguarde.',
  });
  const [isDistributingBase, setIsDistributingBase] = useState(false);
  const {
    assistantTeam,
    totalSelectedIrs,
    controlFilter,
    setAssistantTeam,
    setControlFilter,
    setSideSheetTeamsOpen,
  } = useControlMailingStore(useShallow((state) => state));
  const { data, isLoading, error, refetch } = useGetAssistantTeamMembers();
  const [tableResult, setTableResult] = useState<AssistantTeamMembersTable[]>([]);
  const table = useReactTable<AssistantTeamMembersTable>({
    data: tableResult,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    sortingFns: {
      name: (a: Row<AssistantTeamMembersTable>, b: Row<AssistantTeamMembersTable>) =>
        a.original.name.localeCompare(b.original.name),
      email: (a: Row<AssistantTeamMembersTable>, b: Row<AssistantTeamMembersTable>) =>
        a.original.email.localeCompare(b.original.email),
    },
  });

  const selectedRows = table?.getSelectedRowModel().rows;

  useEffect(() => {
    if (data && assistantTeam) {
      setTableResult(formatData(data, assistantTeam));
    } else {
      setTableResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, assistantTeam]);

  if (isLoading || error) {
    return <LoadStatus loadStatus={error ? 'error' : 'loading'} onRetryClick={refetch} />;
  }

  const resetAction = () => {
    setAssistantTeam(undefined);
    setControlFilter(undefined);
    setSideSheetTeamsOpen(false);
    navigate('/control/distribuicao-irs');
  };

  const handleCancel = () => {
    resetAction();
  };

  const submitSegmentation = async () => {
    const emailsList = selectedRows.map((row) => row.original.email);

    const req = {
      filter: controlFilter,
      assistantList: emailsList,
    };
    try {
      setIsDistributingBase(true);
      setShowSlowResponseAlert(true);
      await postDistributeBase(req);

      displayToaster({
        message: 'IRs distribuídas com sucesso!',
      });
      resetAction();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      displayToaster({
        message: 'Um erro ocorreu ao carregar os dados. Tente novamente.',
        kind: 'error',
      });
    }
    setShowSlowResponseAlert(false);
    setIsDistributingBase(false);
  };

  return (
    <ContentWrapper
      css={{
        margin: '$6 0',
        padding: '$6',
        borderRadius: '$medium',
        width: '100%',
      }}
    >
      <Box
        css={{
          paddingBottom: '$4',
        }}
      >
        <TagInfo
          key={totalSelectedIrs}
          text={`${numberFormatter(totalSelectedIrs) ?? '0'} IRs selecionadas para distribuição. Selecione assistentes de destino.`}
        />
      </Box>
      <Flex direction='row' justify='space-between' gapX='$4' align='top'>
        <Box>
          <ControlFiler />
        </Box>
        <Flex gapX='$4'>
          <FloraButton hierarchy='secondary' onClick={handleCancel}>
            Cancelar
          </FloraButton>
          <FloraButton
            onClick={submitSegmentation}
            disabled={isDistributingBase || selectedRows.length === 0}
            isLoading={isDistributingBase}
          >
            Concluir distribuição
          </FloraButton>
        </Flex>
      </Flex>
      <Box css={{ marginTop: '$4' }}>
        <DataTable tableState={table} hasTableActions />
      </Box>
    </ContentWrapper>
  );
};
