import { baseControlUrl, fetcher } from '@/api';
import { BaseIrs, FilterOptions } from '@/types';

export const getBaseIrsData = async (req: FilterOptions): Promise<BaseIrs[]> => {
  const { data } = await fetcher.post(`${baseControlUrl}base-irs`, {
    body: req,
  });

  return data;
};
