import { OperationEnum } from '@/types';

export const operationOptions = [
  {
    label: 'Boticário',
    value: OperationEnum.BOTI,
  },
  {
    label: 'VDF',
    value: OperationEnum.VDF,
  },
  {
    label: 'CII',
    value: OperationEnum.CII,
  },
];
