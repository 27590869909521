export enum MailingQueryKey {
  TOTAL_OPEN_SERVICES = 'totalOpenServices',
  MAILING_LIST_IR = 'mailingListIr',
}

export enum ControlQueryKey {
  BASE_IR = 'baseIr',
  ASSISTANT_TEAM_MEMBERS = 'assistantTeamMembers',
  ASSISTANT_TEAMS = 'assistantTeams',
}
