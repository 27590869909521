import { DatePicker, InputField, SelectDropdown } from '@/shared';
import { useControlMailingStore } from '@/store';
import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Heading,
  FloraButton as Button,
  Flex,
} from '@grupoboticario/flora-react';
import { useShallow } from 'zustand/react/shallow';
import {
  singleSelectOptions,
  riskAnalysisOptions,
  tabulationOptions,
  operationOptions,
} from './costants';
import { FilterOptions } from '@/types';
import { displayToaster, removeNonNumericAndSemicolonChars } from '@/utils';
import { formatterDefaultValues, formatterFilter } from './functions';
import { ChangeEvent, useState } from 'react';
import { useGetBaseIrsData } from '@/api';

export const SideSheetFilter = () => {
  const { setSideSheetFilterOpen, sideSheetFilterOpen, controlFilter, setControlFilter } =
    useControlMailingStore(useShallow((state) => state));
  const [filter, setFilter] = useState<FilterOptions>(
    formatterDefaultValues(controlFilter) || undefined
  );
  const { refetch } = useGetBaseIrsData();

  const resetFilter = () => {
    setControlFilter(undefined);
    setFilter(undefined);
    refetch();
  };

  const handleChangeValue = (value: string, idFilter?: string) => {
    setFilter((prevFilter) => ({ ...prevFilter, [idFilter]: value }));
  };

  const submitFilter = () => {
    const formattedFilter = formatterFilter(filter);
    setControlFilter(formattedFilter);
    setSideSheetFilterOpen(false);
    displayToaster({
      message: 'Filtros Aplicados!',
      duration: 3000,
    });
    refetch();
  };

  const onChangeCpCode = (value: string) => {
    const formattedValue = removeNonNumericAndSemicolonChars(value);
    handleChangeValue(formattedValue, 'cpCode');
  };

  return (
    <Drawer
      open={sideSheetFilterOpen}
      onOpenChange={() => setSideSheetFilterOpen(!setSideSheetFilterOpen)}
    >
      <DrawerContent side='right'>
        <DrawerCloseButton />
        <DrawerHeader
          css={{
            borderBottom: '1px solid $nonInteractivePredominant',
          }}
        >
          <Heading level='5' data-testid='drawer-header-title'>
            Filtros
          </Heading>
        </DrawerHeader>
        <DrawerBody css={{ height: '100%' }} id='filter-control'>
          <Flex direction={'column'} gapY='$3'>
            <SelectDropdown
              id='haveCredit'
              selectDropdownItem={singleSelectOptions}
              onChange={(e) => handleChangeValue(e.target.value, 'haveCredit')}
              value={filter?.haveCredit}
              labelText='Crédito'
              placeholder='Sim, Não, Todos'
            />
            <SelectDropdown
              id='riskAnalysis'
              selectDropdownItem={riskAnalysisOptions}
              onChange={(e) => handleChangeValue(e.target.value, 'riskAnalysis')}
              labelText='Risco'
              placeholder='Baixo, Médio, Alto, Sem risco'
              value={filter?.riskAnalysis}
              multiple
            />
            <SelectDropdown
              id='tabulation'
              selectDropdownItem={tabulationOptions}
              onChange={(e) => handleChangeValue(e.target.value, 'tabulation')}
              labelText='Tabulação'
              placeholder='Informe o nome ou código'
              value={filter?.tabulation}
              multiple
            />

            <SelectDropdown
              id='haveIrAssigned'
              selectDropdownItem={singleSelectOptions}
              onChange={(e) => handleChangeValue(e.target.value, 'haveIrAssigned')}
              placeholder='Sim, Não, Todos'
              labelText='IRs distribuídas:'
              value={filter?.haveIrAssigned}
            />

            <SelectDropdown
              id='recadastro'
              selectDropdownItem={singleSelectOptions}
              onChange={(e) => handleChangeValue(e.target.value, 'recadastro')}
              placeholder='Sim, Não, Todos'
              labelText='Recadastro:'
              value={filter?.recadastro}
            />

            <SelectDropdown
              id='operation'
              selectDropdownItem={operationOptions}
              onChange={(e) => handleChangeValue(e.target.value, 'operation')}
              placeholder='O Boticário, VDF, etc.'
              labelText='Operação:'
              value={filter?.operation}
              multiple
            />
            <DatePicker
              placeholderText='Data inicial de cadastro:'
              selected={filter?.startDate}
              onChange={(date: Date) => handleChangeValue(date.toISOString(), 'startDate')}
              name='startDate'
            />

            <DatePicker
              placeholderText='Data final de cadastro:'
              selected={filter?.endDate}
              onChange={(date: Date) => handleChangeValue(date.toISOString(), 'endDate')}
              name='endDate'
            />

            <InputField
              value={filter?.cpCode ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeCpCode(e.target.value)}
              label='Código Pai(CP)'
              placeholder='Digite o código'
              id='cpCode'
              focusMessage='Código-pai: Separe os códigos com ponto e vírgula'
            />
            <InputField
              value={filter?.assistants ?? ''}
              onChange={(e) => handleChangeValue(e.target.value, 'assistants')}
              label='Redistribuição de IRs'
              placeholder='Digite o email'
              focusMessage='Redistribuição de IRs: Separe os e-mails com ponto e vírgula'
              id='assistants'
            />
          </Flex>
        </DrawerBody>
        <DrawerFooter
          css={{
            borderTop: '1px solid $nonInteractiveAltAuxiliar',
          }}
        >
          <Button
            hierarchy='secondary'
            css={{ width: '50%' }}
            onClick={() => (controlFilter ? resetFilter() : setSideSheetFilterOpen(false))}
          >
            {controlFilter ? 'Limpar filtros' : 'Cancelar'}
          </Button>

          <Button
            data-testid='btn-apply-filter'
            css={{ width: '50%' }}
            onClick={submitFilter}
            disabled={!filter}
          >
            Aplicar filtros
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
