import { toLocaleDateTime } from '@grupoboticario/vdi-mfe-utils';

export const dateFormatter = (date: string | undefined): string => {
  if (!date) {
    return '- - -';
  }
  return toLocaleDateTime(date, {
    format: (newDate: string) => newDate.replace(',', ''),
    locale: 'pt-BR',
    second: undefined,
    minute: undefined,
    hour: undefined,
    timeZone: 'America/Sao_Paulo',
  });
};
