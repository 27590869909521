import { FilterOptions } from '@/types';

const formatterInputSendValue = (value: string) =>
  !!value && value.length ? value?.split(';').map((part) => part.trim()) : undefined;

const formatterInputMultiValue = (value: string[]): string[] =>
  value?.map((v) => v.toString()).join(';') as unknown as string[];

export const formatterDefaultValues = (data: FilterOptions) => {
  if (!data) {
    return undefined;
  }

  return {
    ...data,
    cpCode: formatterInputMultiValue(data.cpCode),
    assistants: formatterInputMultiValue(data.assistants),
  };
};

export const formatterFilter = (data: FilterOptions) => {
  const formattedFilter: FilterOptions = {
    ...data,
    cpCode: formatterInputSendValue(data.cpCode as unknown as string),
    assistants: formatterInputSendValue(data.assistants as unknown as string),
  };

  const cleanupFilter = Object.entries(formattedFilter).reduce((acc, [key, value]) => {
    if (
      value !== undefined &&
      value !== 'undefined' &&
      (!Array.isArray(value) || value.length > 0)
    ) {
      acc[key as keyof FilterOptions] = value;
    }
    return acc;
  }, {} as FilterOptions);

  return cleanupFilter;
};
