import { baseControlUrl, fetcher } from '@/api';
import { FilterOptions } from '@/types';

export const getDownloadDialerCsv = async (req: FilterOptions) => {
  const { data } = await fetcher.post(`${baseControlUrl}download-dialer-csv`, {
    body: req,
    responseType: 'blob',
    timeout: 60 * 1000 * 8,
  });

  return data;
};
