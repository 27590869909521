import { AssistantTeamMembersTable } from '@/types';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { ActionCell, TextCell } from '@/shared';

const columnHelper = createColumnHelper<AssistantTeamMembersTable>();
export const Columns: ColumnDef<AssistantTeamMembersTable>[] = [
  columnHelper.display({
    id: 'actions',
    enableSorting: false,
    cell: (props) => (
      <ActionCell
        isSelected={props.row.getIsSelected()}
        onChange={() => props.row.toggleSelected()}
        id={`${props.row.original.email}-${props.row.original.name}-${props.row.original.groupId}`}
        data-selected={props.row.getIsSelected()}
      />
    ),
    meta: {
      gridColumn: '100px',
    },
  }),
  columnHelper.accessor('name', {
    header: 'Assistente',
    cell: (props) => <TextCell text={props.getValue()} />,
    meta: {
      gridColumn: '2fr',
    },
  }),
  columnHelper.accessor('email', {
    header: 'E-mail',
    cell: (props) => <TextCell text={props.getValue()} />,
  }),
  columnHelper.accessor('responsibleName', {
    header: 'Equipe',
    enableSorting: false,
    cell: (props) => <TextCell text={props.getValue()} />,
  }),
];
