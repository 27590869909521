export const singleSelectOptions = [
  {
    label: 'Sim',
    value: 'sim',
  },
  {
    label: 'Não',
    value: 'nao',
  },
  {
    label: 'Todos',
    value: 'undefined',
  },
];
