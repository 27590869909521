import { Box, Flex, Text, TagProps } from '@grupoboticario/flora-react';
import { FilterTag } from '../tags';

interface FilterTagResultType {
  name: string;
  key: string;
}

interface FilterTagProps {
  filterTagResult: FilterTagResultType[];
  onChangeFilter?: (filters: string[]) => void;
  variant?: TagProps['variant'];
}

export const FilterTagList = ({ filterTagResult, onChangeFilter, variant }: FilterTagProps) => {
  const handleDelete = (filterToDelete: FilterTagResultType) => {
    if (!onChangeFilter && !!filterTagResult) {
      return;
    }
    const updatedFilter = filterTagResult
      .filter((filter: FilterTagResultType) => filter.key !== filterToDelete.key)
      .map((filter) => filter.key);
    onChangeFilter(updatedFilter);
  };

  return (
    <Flex css={{ width: '100%' }} justify='space-between'>
      <Box>
        {!!filterTagResult.length ? (
          <>
            <Text
              as='p'
              size='exceptionsAuxiliarMedium'
              color='$nonInteractiveAuxiliar'
              css={{ paddingBottom: '$2', fontWeight: '500' }}
            >
              Filtros aplicados
            </Text>
            <Flex align='center' gap='$2' wrap='wrap'>
              {filterTagResult.map((filter: FilterTagResultType) => (
                <FilterTag
                  key={filter.key}
                  filterName={filter.name}
                  handleDelete={() => handleDelete(filter)}
                  variant={variant}
                />
              ))}
            </Flex>
          </>
        ) : null}
      </Box>
    </Flex>
  );
};
