import { HelperTooltip } from '@/shared';
import { numberFormatter } from '@/shared/utils';
import { useControlMailingStore } from '@/store';
import { TextColorsEnum, TextInfoProps } from '@/types';
import { toPercent } from '@/utils';
import { Flex, Text, TextProps } from '@grupoboticario/flora-react';
import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';
import { useShallow } from 'zustand/react/shallow';

interface IrDataCardPercentInfoProps {
  textInfo: TextInfoProps[];
}

const textColor: Record<TextColorsEnum, TextProps['color']> = {
  [TextColorsEnum.SUCCESS]: '$success-standard',
  [TextColorsEnum.WARNING]: '$alert-standard',
  [TextColorsEnum.ERROR]: '$error-standard',
  [TextColorsEnum.NO_COLOR]: '$statusErrorNonInteractivePredominant',
};

export const IrDataCardPercentInfo = ({ textInfo }: IrDataCardPercentInfoProps) => {
  const { baseIrsData } = useControlMailingStore(useShallow((state) => state));
  return (
    <Flex gapX='$12' css={{ paddingTop: '$3' }}>
      {textInfo.map((info) => (
        <Flex direction='column' key={info.title} data-testid='text-info'>
          <Flex gapX='$1'>
            <Text size='exceptionsAuxiliarMedium'>{info.title} </Text>
            <HelperTooltip
              text={numberFormatter(info.value)}
              icon={
                <QuestionMarkCircleIcon
                  css={{
                    '& svg': {
                      scale: '0.7',
                    },
                  }}
                />
              }
              direction='right'
            />
          </Flex>
          <Text size='exceptionsAuxiliarMedium' color={textColor[info.color] as TextProps['color']}>
            {baseIrsData.length ? toPercent(baseIrsData[0]?.baseIr, info.value) : '0'}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};
