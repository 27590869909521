import { useOpenServicesCount } from '@/api';
import { displayToaster } from '@/utils';
import { Text } from '@grupoboticario/flora-react';

const errorMessage = 'Sem informação de atendimentos abertos';
const loadingMessage = 'Carregando...';

export const OpenServicesCount = () => {
  const { totalOpenServices, isLoading, error, refetch } = useOpenServicesCount();

  const openServicesMessage = `${totalOpenServices?.total}
    ${totalOpenServices?.total > 1 ? ' atendimentos abertos' : ' atendimento aberto'}`;

  if (error) {
    displayToaster({
      kind: 'error',
      message: 'Não foi possível exibir os atendimentos abertos.',
      callbackFunction: refetch,
    });
    return (
      <Text color={'$nonInteractiveAuxiliar'} size='bodyMediumStandard'>
        {errorMessage}
      </Text>
    );
  }

  return (
    <Text color={'$nonInteractiveAuxiliar'} size='bodyMediumStandard'>
      {!isLoading ? openServicesMessage : loadingMessage}
    </Text>
  );
};
