import { getDownloadDialerCsv, useGetBaseIrsData } from '@/api';
import { IrDistributionDataControl, SideSheetFilter, SideSheetTeams } from '@/components/control';
import { ContentWrapper, FilterButton, LoadStatus, TagInfo } from '@/shared';
import { useControlMailingStore } from '@/store';
import { FilterOptions } from '@/types';
import { displayToaster, useSlowResponseAlert } from '@/utils';
import { Box, FloraButton, Flex } from '@grupoboticario/flora-react';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

const HIGH_VOLUME_IRS_THRESHOLD: string = '30000';

export const IrDistributionFilter = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    baseIrsData,
    controlFilter,
    setSideSheetFilterOpen,
    setSideSheetTeamsOpen,
    setTotalSelectedIrs,
  } = useControlMailingStore(useShallow((state) => state));
  const { isLoading, error, refetch } = useGetBaseIrsData();
  const setShowSlowResponseAlert = useSlowResponseAlert({
    message: 'Seu arquivo está sendo processado. Por favor, aguarde',
  });

  const irBaseAvailable = baseIrsData?.[0]?.baseIr;

  useEffect(() => {
    if (baseIrsData) {
      setTotalSelectedIrs(irBaseAvailable ?? '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseIrsData]);

  if (isLoading || error) {
    return <LoadStatus loadStatus={isLoading ? 'loading' : 'error'} onRetryClick={refetch} />;
  }

  const filterCount = (filter: FilterOptions) => {
    if (!filter) {
      return 0;
    }
    return Object.entries(filter).reduce((count, [, value]) => {
      return count + (Array.isArray(value) ? value.length : 1);
    }, 0);
  };

  const isBaseEmpty = !baseIrsData?.length;

  const handleDownloadDialerCsv = async () => {
    try {
      setIsDownloading(true);
      setShowSlowResponseAlert(true);
      const response = await getDownloadDialerCsv(controlFilter);
      const urlObject = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = urlObject;
      link.download = 'mailing.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlObject);
      displayToaster({
        message: 'Dados exportados com sucesso!',
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      displayToaster({
        message: 'Um erro ocorreu ao carregar os dados. Tente novamente.',
        kind: 'error',
      });
    }
    setIsDownloading(false);
    setShowSlowResponseAlert(false);
  };

  return (
    <>
      <ContentWrapper
        css={{
          border: '1px solid $colors$nonInteractiveAltAuxiliar',
        }}
      >
        <Box
          css={{
            backgroundColor: '$colors$backgroundSecondary',
            margin: '$6 0',
            padding: '$6',
            borderRadius: '$medium',
            width: '100%',
          }}
        >
          <Box>
            <TagInfo text='Selecione a base para distribuição através dos filtros.' />
          </Box>
          <Flex css={{ paddingTop: '$6' }} justify='flex-end'>
            <FilterButton
              filterCount={filterCount(controlFilter)}
              onClick={() => {
                setSideSheetFilterOpen(true);
              }}
            />
          </Flex>
          <IrDistributionDataControl />

          {irBaseAvailable > HIGH_VOLUME_IRS_THRESHOLD && !!controlFilter ? (
            <Flex justify={'flex-end'} css={{ marginTop: '$4' }}>
              <TagInfo text='Devido ao tamanho da base filtrada, a ação pode demorar um pouco. Caso feche ou atualize o navegador, o processo será interrompido. Por favor, aguarde' />
            </Flex>
          ) : null}

          <Flex css={{ paddingTop: '$6' }} justify='flex-end' gapX='$3'>
            <FloraButton
              hierarchy='secondary'
              onClick={handleDownloadDialerCsv}
              disabled={isDownloading || isBaseEmpty || !controlFilter}
              isLoading={isDownloading}
            >
              Exportar dados das IRs
            </FloraButton>
            <FloraButton
              disabled={isBaseEmpty || !controlFilter}
              onClick={() => {
                setSideSheetTeamsOpen(true);
              }}
            >
              Distribuir IRs
            </FloraButton>
          </Flex>
        </Box>
        <SideSheetFilter />
        <SideSheetTeams />
      </ContentWrapper>
    </>
  );
};
