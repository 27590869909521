import { MailingQueryKey } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getMailingData } from '@/api';
import { GetMailingDataParams } from '@/types';
import { SortingState } from '@tanstack/react-table';
export interface LoadDataProps {
  page?: number;
  pageSize?: number;
  filter?: string[];
  sorting?: SortingState;
}
type FilterAccumulator = {
  [key: string]: string;
};

const formatAndGroupFilters = (filters: string[]) => {
  const groupedFilters = (filters ?? []).reduce((acc: FilterAccumulator, item: string) => {
    const [prefix, value] = item.split(':');
    const currentValue = acc[prefix]?.split(',') ?? [];
    acc[prefix] = [...currentValue, value].join(',');
    return acc;
  }, {});

  return groupedFilters ?? {};
};

const formatSortData = (sortData: SortingState) =>
  sortData.map((item) => ({
    field: item.id,
    sort: item.desc ? 'ASC' : 'DESC',
  }));

const queryParamsFormatter = ({ page, pageSize, filter, sorting = [] }: LoadDataProps) => {
  const formattedGroupFilters = formatAndGroupFilters(filter);
  const sort = formatSortData(sorting)[0];
  const queryParams: GetMailingDataParams = {
    page,
    limit: pageSize,
    ...sort,
    ...formattedGroupFilters,
  };

  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(queryParams).filter(([_, value]) => value !== null && value !== undefined)
  );
};

export const useMailingData = (params: LoadDataProps) => {
  const fetchQuery = useQuery({
    queryKey: [MailingQueryKey.MAILING_LIST_IR, params],
    queryFn: () => {
      const formattedQueryParams = queryParamsFormatter(params);
      return getMailingData(formattedQueryParams);
    },
    gcTime: 0,
  });

  return {
    data: fetchQuery.data,
    ...fetchQuery,
  };
};
