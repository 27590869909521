import { FloraCheckbox, Flex, Tooltip, Text } from '@grupoboticario/flora-react';
import { Table } from '@tanstack/react-table';

interface TableActionsProps<T> {
  tableState: Table<T>;
  tableButtonsMenu?: React.ReactNode;
}

export const TableActions = <T,>({ tableState }: TableActionsProps<T>) => {
  const { rows } = tableState.getSelectedRowModel();
  const selectedRows = rows.length;
  const hasSelectedRows = selectedRows > 0;

  const toggleAllRowsSelected = () => {
    tableState.toggleAllRowsSelected();
  };

  const resetRowSelection = () => {
    tableState.resetRowSelection();
  };

  return (
    <Flex align='center' gapX='$4' css={{ paddingLeft: '$2', height: '40px' }}>
      <Flex align='center'>
        <Tooltip align='center' direction='bottom' showArrow text='Selecionar todos'>
          <FloraCheckbox
            data-testid='table-row-select'
            id='selectAll'
            indeterminate={hasSelectedRows}
            checked={hasSelectedRows}
            css={{ width: '$5', height: '$5' }}
            onClick={!hasSelectedRows ? toggleAllRowsSelected : resetRowSelection}
          />
        </Tooltip>
        <Text
          size='exceptionsAuxiliarMedium'
          color='$nonInteractiveAuxiliar'
          css={{ marginLeft: '$2', fontWeight: '500' }}
        >
          {!hasSelectedRows ? 'Nenhum item selecionado' : `${selectedRows} item selecionado`}
        </Text>
      </Flex>
    </Flex>
  );
};
