import { Column, flexRender, Header, HeaderGroup, Table } from '@tanstack/react-table';
import { Box, Flex, Grid, Text } from '@grupoboticario/flora-react';
import { TableCell } from './table-cell';
import { HelperTooltip } from '../tooltip';
import { SortIcon } from '../sort-icon';
import { columnHelperMetaProps } from './types';
import { TableActions } from './table-actions';

interface DataTableProps<T> {
  tableState: Table<T>;
  tableFooter?: React.ReactNode;
  tableEmptyMessage?: string;
  hasTableActions?: boolean;
}

export const DataTable = <T,>({
  tableState,
  tableFooter,
  hasTableActions = false,
  tableEmptyMessage = 'Nenhum dado encontrado',
}: DataTableProps<T>) => {
  const gridTemplateColumns =
    tableState &&
    tableState
      .getAllColumns()
      .map((column: Column<T, unknown>) => {
        const columnMeta: columnHelperMetaProps = column.columnDef.meta;
        return columnMeta?.gridColumn ?? '1fr';
      })
      .join(' ');

  const hasRows = tableState?.getRowModel()?.rows.length > 0;

  return (
    <Box css={{ paddingBottom: '$5' }}>
      {hasTableActions ? (
        <Flex
          css={{
            width: '100%',
            padding: '$3 $4',
            background: '$backgroundSecondary',
            borderRadius: '$large',
            marginBottom: '$4',
          }}
          align='center'
        >
          <TableActions tableState={tableState} />
        </Flex>
      ) : null}

      <Grid templateColumns={gridTemplateColumns}>
        {tableState.getHeaderGroups().map((headerGroup: HeaderGroup<T>) =>
          headerGroup.headers.map((header: Header<T, unknown>) => {
            const headerMeta: columnHelperMetaProps = header.column.columnDef.meta;
            return (
              <TableCell
                type='header'
                key={header.id}
                gridColumn={headerMeta?.gridColumn ?? 'span 1'}
              >
                <Text
                  size='bodyMediumShort'
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: header.column.getCanSort() ? 'pointer' : 'default',
                  }}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getCanSort() ? <SortIcon /> : null}
                  {headerMeta?.helperTooltip ? (
                    <HelperTooltip isQuestionMark text={headerMeta.helperTooltip} />
                  ) : null}
                </Text>
              </TableCell>
            );
          })
        )}
        {hasRows ? (
          tableState.getRowModel().rows.map((row, index) =>
            row.getVisibleCells().map((cell) => (
              <TableCell
                index={index}
                isSelected={row.getIsSelected()}
                key={`${row.original}-${cell.id}`}
                id={`${row.original}-${cell.id}`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))
          )
        ) : (
          <TableCell gridColumn='9' justify='center'>
            {tableEmptyMessage}
          </TableCell>
        )}
      </Grid>
      {tableFooter ? tableFooter : null}
    </Box>
  );
};
