import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Flex } from '@grupoboticario/flora-react';
import { GetMailingDataResponse, MailingData } from '@/types';
import {
  ContentWrapper,
  FilterButton,
  LoadStatus,
  useEvents,
  useDisableTextSelection,
  DataTable,
} from '@/shared';
import { useMailingData } from '@/api';
import { useEffect, useState } from 'react';
import { useAssistantMailingStore } from '@/store';
import {
  OpenServicesCount,
  Columns as columns,
  StartServiceModal,
  AssistantFilter,
  AssistantTableMenu,
  AssistantFilterDrawer,
  TableFooter,
} from '@/components';
import { useShallow } from 'zustand/react/shallow';

const initialMailingDataResponse: GetMailingDataResponse = {
  data: [],
  limit: 10,
  page: 0,
  totalDocs: 0,
  totalPages: 0,
};

export const Mailing = () => {
  const events = useEvents();
  useDisableTextSelection();

  const [tableResult, setTableResult] = useState<GetMailingDataResponse>(
    initialMailingDataResponse
  );

  const { tableMailing, setAssistantFilterOpen } = useAssistantMailingStore(
    useShallow((state) => state)
  );
  const { tableState, setTableState } = tableMailing;

  const table = useReactTable<MailingData>({
    data: tableResult.data,
    columns,
    pageCount: tableResult.totalPages,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    enableMultiRowSelection: true,
    meta: {
      totalDocs: tableResult.totalDocs,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const { pagination, globalFilter, sorting } = tableState?.getState() ?? {
    pagination: { pageIndex: 0, pageSize: 10 },
    sorting: [
      {
        id: 'name',
        desc: true,
      },
    ],
  };

  const { data, isLoading, error, refetch } = useMailingData({
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
    filter: globalFilter,
    sorting,
  });

  useEffect(() => {
    setTableState(table);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setTableResult(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <LoadStatus loadStatus='loading' />;
  }

  if (error) {
    return <LoadStatus loadStatus='error' onRetryClick={refetch} />;
  }

  const onClickFilter = () => {
    setAssistantFilterOpen(true);
    events.interactionFilterButton('filtro');
  };

  const configDataTable = {
    tableState,
    tableFooter: <TableFooter />,
    emptyMessage: 'Nenhum registro encontrado',
  };

  return (
    <ContentWrapper>
      <Flex css={{ width: '100%', padding: '$6 $1 $4' }} justify='space-between'>
        <AssistantFilter />
        <FilterButton onClick={onClickFilter} size={'large'} />
      </Flex>
      <Flex
        css={{
          width: '100%',
          padding: '$3 $4',
          background: '$backgroundSecondary',
          borderRadius: '$large',
          marginBottom: '$4',
        }}
        align='center'
        justify='space-between'
      >
        <AssistantTableMenu />
        <OpenServicesCount />
      </Flex>
      <DataTable {...configDataTable} />
      <StartServiceModal />
      <AssistantFilterDrawer />
    </ContentWrapper>
  );
};
